import { PageType } from '../../shared/enums/pageType';

export enum TrackingPageTypes {
    CategoryLanding = 'CategoryLanding',
    SearchPage = 'SearchPage',
    Redemption = 'Redemption',
    Other = 'CategoryLanding',
}

export const trackingTypeByPage = {
    [PageType.ProductListingPage]: TrackingPageTypes.CategoryLanding,
    [PageType.SearchPage]: TrackingPageTypes.SearchPage,
    [PageType.Redemption]: TrackingPageTypes.Redemption,
    [PageType.Other]: TrackingPageTypes.Other,
};
