import { defineMessages, useIntl } from 'react-intl';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { GA4EventName, GA4FilterListType, trackFilterInteraction } from '@jsmdg/tracking';
import { FilterDropdownBase, NumericRange } from '@jsmdg/yoshi';
import { StaticFilter } from '../../../../shared/enums/staticFilter';
import { type FragmentContext } from '../../../../shared/types/fragmentContext';
import { type Filter as FilterType } from '../../../../shared/types/search';
import { PriceFilterDefault } from '../../../enums/priceFilterDefault';
import { getGridView } from '../../../helper/getGridView';
import { usePriceFilter } from '../../../hooks';
import { type SearchReducerActionType } from '../../../reducers/searchReducer';
import { type Nullable } from '../../../types';
import { InputPriceControls } from './InputPriceControls/InputPriceControls';

const PRICE_BOUNDARY_MIN = PriceFilterDefault.Min;
const PRICE_BOUNDARY_MAX = PriceFilterDefault.Max;

const messages = defineMessages({
    dropdownLabel: {
        defaultMessage: 'Preis',
    },
    submitLabel: {
        defaultMessage: 'Fertig',
    },
    resetLabel: {
        defaultMessage: 'Zurücksetzen',
    },
});

type PriceFilterProps = {
    readonly currencyCode: string;
    readonly onSubmit: (
        label: SearchReducerActionType,
        value: { min: Nullable<number>; max: Nullable<number> } | Nullable<number>,
    ) => void;
    readonly shouldReset?: boolean;
    readonly values: Array<Nullable<number>>;
    readonly isMapView?: boolean;
    readonly filter: FilterType;
    readonly listType?: GA4FilterListType;
};

const PriceFilter = ({
    currencyCode,
    filter,
    isMapView,
    listType,
    onSubmit,
    shouldReset,
    values,
}: PriceFilterProps): JSX.Element => {
    const {
        handleOnClickOutside,
        handleReset,
        inputsValues,
        onInputUpdate,
        onPriceChange,
        onRangeChange,
        priceData,
        rangeValues,
        sliderDidUpdate,
    } = usePriceFilter({
        currencyCode,
        onSubmit,
        options: { min: PRICE_BOUNDARY_MIN, max: PRICE_BOUNDARY_MAX },
        shouldReset,
        values,
        isMapView,
        filter,
        listType,
    });
    const intl = useIntl();
    const { settingCookie } = useFragmentContext<FragmentContext>();
    const gridView = getGridView(settingCookie);

    const onOpenPriceFilter = async (): Promise<void> => {
        await window.yieldToMainThread();
        trackFilterInteraction(`Expand_${StaticFilter.Price}`, undefined, {
            eventName: GA4EventName.ExpandFilter,
            filter_type: 'Price',
            list_type: listType ?? GA4FilterListType[gridView],
        });
    };

    return (
        <>
            <div
                className={isMapView ? 'd-none' : 'd-none d-sm-block mr-1x'}
                data-testid="price-filter"
            >
                <FilterDropdownBase
                    label={intl.formatMessage(messages.dropdownLabel)}
                    resetLabel={intl.formatMessage(messages.resetLabel)}
                    submitLabel={intl.formatMessage(messages.submitLabel)}
                    numSelected={!priceData.price[0] && !priceData.price[1] ? 0 : 1}
                    showNumSelected
                    onSubmit={() => {
                        onPriceChange(rangeValues);
                    }}
                    onReset={handleReset}
                    onClickOutside={handleOnClickOutside}
                    hasChanged={sliderDidUpdate}
                    dataTestId="product-price-button"
                    onOpen={onOpenPriceFilter}
                >
                    <div className="px-3x">
                        <InputPriceControls
                            inputsValues={inputsValues}
                            onInputUpdate={onInputUpdate}
                        />

                        <NumericRange
                            values={rangeValues}
                            onChange={((val: number[]) => onRangeChange(val, true)) as () => void}
                            options={[PRICE_BOUNDARY_MIN, PRICE_BOUNDARY_MAX]}
                            step={10}
                            pushable={10}
                        />
                    </div>
                </FilterDropdownBase>
            </div>
            <div className={isMapView ? 'd-block pt-2x' : 'd-block d-sm-none pt-2x'}>
                <InputPriceControls inputsValues={inputsValues} onInputUpdate={onInputUpdate} />

                <NumericRange
                    values={rangeValues}
                    onChange={((val: number[]) => onRangeChange(val, true)) as () => void}
                    options={[PRICE_BOUNDARY_MIN, PRICE_BOUNDARY_MAX]}
                    step={10}
                    pushable={10}
                />
            </div>
        </>
    );
};

export { PriceFilter };
