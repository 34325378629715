import classNames from 'classnames';

import { GA4EventName, GA4FeatureCategory } from '@jsmdg/tracking';
import { ChevronIcon, Link, LinkVariant, Slider, SlidingDirections } from '@jsmdg/yoshi';
import { type PageType } from '../../../shared/enums/pageType';
import { type PageSliderData } from '../../../shared/types/pageContent';
import { type TrackingCreativePrefix } from '../../enums/trackingCreativePrefix';
import { handleSlidingDirections } from '../../helper/handleSlidingDirections.tracking';
import { useHighestMatchingBreakpoint } from '../../hooks';
import { Headline } from '../Headline/Headline';
import { PageSlide } from '../PageSlide';
import { pageSliderConfig } from './pageSliderConfig';
import styles from './PageSlider.module.scss';

type PageSliderProps = {
    readonly slides: PageSliderData[];
    readonly headline?: string;
    readonly verticalPosition: number;
    readonly hasNavigationCreative: boolean;
    readonly pageType: PageType;
    readonly crossLinkPageTitle?: string;
    readonly crossLinkPageRef?: string;
    readonly trackingPrefix: TrackingCreativePrefix;
    readonly trackingPath?: string[];
};

const PageSlider = ({
    crossLinkPageRef,
    crossLinkPageTitle,
    hasNavigationCreative,
    headline,
    pageType,
    slides,
    trackingPath,
    trackingPrefix,
    verticalPosition,
}: PageSliderProps): JSX.Element | null => {
    const highestMatchingBreakpoint = useHighestMatchingBreakpoint();
    const sliderClasses = {
        controlsContainer: 'mb-0',
        controlsWrapper: 'mt-1-5x mt-xs-2x',
    };

    const sliderConfig = {
        ...pageSliderConfig[pageType][highestMatchingBreakpoint],
    };

    if (!slides || slides.length === 0 || !sliderConfig) {
        return null;
    }

    const preparedSlides = slides.map((slide, index) => ({
        key: slide.title,
        content: (
            <PageSlide
                linkUrl={slide.linkUrl}
                title={slide.title}
                image={slide.image}
                trackingName={slide.trackingName}
                trackingPath={trackingPath}
                key={slide.title}
                position={`vertical${verticalPosition}_horizontal${index + 1}`}
                positionHorizontal={`${index + 1}`}
                positionVertical={`${
                    hasNavigationCreative ? verticalPosition - 1 : verticalPosition
                }`}
                pageType={pageType}
                trackingPrefix={trackingPrefix}
            />
        ),
    }));

    const handleSlideChange = async (direction: SlidingDirections): Promise<void> =>
        handleSlidingDirections({
            direction,
            eventData: {
                eventName: GA4EventName.SlideNavigationItem,
                feature_category: GA4FeatureCategory.PageSliderNavigation,
                click_type: `Slide ${direction.toLowerCase()}`,
                position_vertical: verticalPosition.toString(),
            },
        });

    return (
        <div className={styles.pageSliderWrapper}>
            <div
                className={classNames(
                    `d-flex flex-wrap w-100 align-items-baseline ${
                        headline ? 'justify-content-between' : 'justify-content-end'
                    }`,
                    {
                        'mb-2x': headline || crossLinkPageTitle,
                    },
                )}
            >
                {headline && <Headline title={headline} />}
                {crossLinkPageTitle ? (
                    <Link
                        className={classNames(
                            styles.showMore,
                            'd-inline-flex align-content-center',
                        )}
                        href={crossLinkPageRef}
                        dataTestId="page-slider-show-more-link"
                        variant={LinkVariant.Brand}
                        internal
                    >
                        <span>{crossLinkPageTitle}</span>
                        <ChevronIcon />
                    </Link>
                ) : null}
            </div>
            <div className={classNames(styles.sliderWrapper)}>
                <Slider
                    slides={preparedSlides}
                    config={sliderConfig}
                    classes={sliderClasses}
                    handleShowNext={async () => handleSlideChange(SlidingDirections.Next)}
                    handleShowPrevious={async () => handleSlideChange(SlidingDirections.Previous)}
                />
            </div>
        </div>
    );
};

export { PageSlider };
