import classnames from 'classnames';

import {
    Skeleton,
    SkeletonAnimation,
    SkeletonVariant,
    Slider,
    SlidingDirections,
} from '@jsmdg/yoshi';
import { type PageType } from '../../../shared/enums/pageType';
import { type PageTile as TPageTile } from '../../../shared/types/pageContent';
import { ProductListItemType } from '../../enums/productListItemType';
import { type TrackingCreativePrefix } from '../../enums/trackingCreativePrefix';
import { CreativeName } from '../../enums/trackingPromotions';
import { trackSlideNavigationClick } from '../../helper/trackSlide';
import { useHighestMatchingBreakpoint, useIsLoading } from '../../hooks';
import { PageSlide } from '../PageSlide';
import { pageTilesConfig, pageTilesConfigBundleBox } from './pageTilesConfig';
import styles from './PageTiles.module.scss';

type PageTilesProps = {
    readonly tiles: TPageTile[];
    readonly pageType: PageType;
    readonly identifier: string;
    readonly verticalPosition: number;
    readonly hasNavigationCreative: boolean;
    readonly trackingPrefix: TrackingCreativePrefix;
    readonly productListItemType?: ProductListItemType;
};

const PageTiles = ({
    hasNavigationCreative,
    identifier,
    pageType,
    productListItemType,
    tiles,
    trackingPrefix,
    verticalPosition,
}: PageTilesProps): JSX.Element | null => {
    const highestMatchingBreakpoint = useHighestMatchingBreakpoint();

    const sliderConfig =
        productListItemType === ProductListItemType.BundleBoxPlpSlider
            ? pageTilesConfigBundleBox[highestMatchingBreakpoint]
            : pageTilesConfig[highestMatchingBreakpoint];

    const isLoading = useIsLoading();

    if (tiles.length === 0 || !sliderConfig) {
        return null;
    }

    const slides = tiles.map((slide, index) => ({
        key: `${identifier}-${index}`,
        content: (
            <PageSlide
                linkUrl={slide.url}
                title={slide.title}
                image={slide.image}
                trackingName={slide.title?.toLowerCase().replace(' ', '-') || 'no id'}
                key={slide.title}
                position={`vertical${verticalPosition}_horizontal${index + 1}`}
                positionHorizontal={`${index + 1}`}
                positionVertical={`${
                    hasNavigationCreative ? verticalPosition - 1 : verticalPosition
                }`}
                pageType={pageType}
                trackingPrefix={trackingPrefix}
            />
        ),
    }));

    const handleSlideNext = (): void => {
        trackSlideNavigationClick(
            SlidingDirections.Next,
            verticalPosition.toString(),
            CreativeName.PageTile,
        );
    };

    const handleSlidePrevious = (): void => {
        trackSlideNavigationClick(
            SlidingDirections.Previous,
            verticalPosition.toString(),
            CreativeName.PageTile,
        );
    };

    return (
        <div className={classnames(styles.pageTiles, 'w-100 position-relative')}>
            <Slider
                slides={slides}
                config={sliderConfig}
                classes={{
                    controlsContainer: 'mb-0',
                }}
                handleShowNext={handleSlideNext}
                handleShowPrevious={handleSlidePrevious}
            />
            {isLoading ? (
                <div className={`position-absolute contain-content ${styles.skeleton} d-flex`}>
                    <Skeleton
                        animation={SkeletonAnimation.Wave}
                        variant={SkeletonVariant.Rect}
                        width="50%"
                        height="100%"
                    />
                    <Skeleton
                        animation={SkeletonAnimation.Wave}
                        variant={SkeletonVariant.Rect}
                        width="50%"
                        height="100%"
                        className="ml-2x"
                    />
                </div>
            ) : null}
        </div>
    );
};

export { PageTiles };
