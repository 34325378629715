/* eslint-disable complexity */
import { type Dispatch, type SetStateAction } from 'react';
import axios from 'axios';
import classNames from 'classnames';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import {
    AlgoliaAnalyticsEventAction,
    AlgoliaAnalyticsEventPage,
    AlgoliaAnalyticsEventSource,
    GA4EventName,
    GA4FeatureCategory,
    GA4ItemListName,
    GA4ItemListType,
    type ProductType as TrackingProductType,
    type Rating,
    sendAlgoliaObjectIdsConvertedAfterSearchEvent,
    trackAnalyticsEvent,
    trackEecAddToCart,
    type Variant as TrackingVariant,
} from '@jsmdg/tracking';
import { GridView } from '@jsmdg/yoshi';
import { type PageType } from '../../../shared/enums/pageType';
import { ProductType } from '../../../shared/enums/productType';
import { SearchStatus } from '../../../shared/enums/searchStatus';
import { type CampaignCreative } from '../../../shared/types/campaignCreative';
import { type FragmentContext } from '../../../shared/types/fragmentContext';
import { type Search } from '../../../shared/types/search';
import { type Participants, type Price } from '../../../shared/types/searchResponse';
import { ProductListItemType } from '../../enums/productListItemType';
import { TrackingCreativePrefix } from '../../enums/trackingCreativePrefix';
import { useInsightsClient } from '../../hooks/useInsightsClient';
import { type InitialPageFilterType } from '../../types';
import { type ProductListItem } from '../../types/productListItem';
import { Headline } from '../Headline';
import { PageTiles } from '../PageTiles';
import { ProductListPagination } from '../ProductListPagination/ProductListPagination';
import { ProductTileWrapper } from '../ProductTileWrapper';
import { addToCartError } from './errors';
import { ProductBanner } from './ProductBanner';
import styles from './ProductList.module.scss';

type ProductListItemsProps = {
    readonly productListItems: ProductListItem[];
    readonly currentPageSize: number;
    readonly pageType: PageType;
    readonly isFullWidth: boolean;
    readonly lazyLoad: boolean;
    readonly handleCartRedirection: () => void;
    readonly setAddToCartErrorType: Dispatch<SetStateAction<string>>;
    readonly showBanner: boolean;
    readonly hasUiFilters: boolean;
    readonly searchState: Search;
    readonly productTileCreatives: CampaignCreative[];
    readonly productListCreatives: CampaignCreative[];
    readonly selectionBlockCreatives: CampaignCreative[];
    readonly initialPageFilter?: InitialPageFilterType;
    readonly nbPages: number;
    readonly offset: number;
    readonly verticalPosition?: number;
    readonly status: SearchStatus;
    readonly queryId?: string;
    readonly indexName: string;
    readonly gridView: GridView;
};

const ProductListItems = ({
    currentPageSize,
    gridView,
    handleCartRedirection,
    hasUiFilters,
    indexName,
    initialPageFilter,
    isFullWidth,
    lazyLoad,
    nbPages,
    offset,
    pageType,
    productListCreatives,
    productListItems,
    productTileCreatives,
    queryId,
    searchState,
    selectionBlockCreatives,
    setAddToCartErrorType,
    showBanner,
    status,
    verticalPosition = 0,
}: ProductListItemsProps): JSX.Element => {
    const { addProductToCart, featureFlagsVariation, path } = useFragmentContext<FragmentContext>();
    const insightsClientPromise = useInsightsClient();
    const { filter = {}, pagination, searchTerm, sorting } = searchState;

    let productPosition = 0;

    let listCreativeIndex = verticalPosition - 1;

    const showPagination = hasUiFilters && !!pagination && nbPages > 1;

    const isListView = gridView === GridView.ListView;

    const bundleBoxEnabled = featureFlagsVariation?.plp_bundle_box === 'on';

    const getBannerCreatives = (itemType: ProductListItemType): CampaignCreative[] => {
        switch (itemType) {
            case ProductListItemType.ProductTileBanner:
                return productTileCreatives;
            case ProductListItemType.ProductListBanner:
                return productListCreatives;
            case ProductListItemType.BannerSelectionBlock:
                return selectionBlockCreatives;
            default:
                return productTileCreatives;
        }
    };

    const handleAddToCart = async (
        {
            closestLocationDistance,
            discountBadgeId,
            discountBadgeName,
            locationCount,
            locationIsSelected,
            manufacturerSku,
            originalPrice,
            participants,
            price,
            productId,
            productType,
            rating,
            title,
            trackingName,
            trackingPath,
            url,
        }: {
            productId: string;
            manufacturerSku: string;
            trackingPath: string[];
            price: Price;
            originalPrice?: Price;
            title: string;
            productType: ProductType;
            discountBadgeId?: string;
            discountBadgeName?: string;
            url: string;
            rating?: Rating;
            trackingName: string;
            locationCount?: number;
            participants?: Participants;
            locationIsSelected?: boolean;
            closestLocationDistance?: number;
        },
        event: React.MouseEvent<HTMLButtonElement>,
    ): Promise<void> => {
        if (!navigator.cookieEnabled) {
            setAddToCartErrorType(addToCartError.GENERAL_CART_ERROR);
            return;
        }

        let target = event.target as HTMLButtonElement;
        if (!(target instanceof HTMLButtonElement))
            // sometimes the click event comes from nodes inside the button?!
            target = (target as HTMLElement).closest('button') || target;
        target.disabled = true;

        const quantity = 1;
        const promise = addProductToCart(productId, quantity);

        await window.yieldToMainThread();

        trackAnalyticsEvent({
            category: 'ProductTile',
            action: 'ButtonClick',
            label: 'AddToCart',
            eventData: {
                eventName: GA4EventName.ClickEcomCta,
                feature_category: GA4FeatureCategory.ProductList,
                click_element: 'Add To Cart',
                click_text: 'In den Warenkorb',
                click_url: `${window.location.origin}/cart`,
                list_type: GA4ItemListType[gridView],
            },
        });
        trackEecAddToCart({
            product: {
                id: productId,
                url,
                trackingName,
                rating,
                manufacturerSku,
                trackingPath,
                price: {
                    gross: {
                        amount: Number.parseFloat(price.gross.amount),
                        currencyCode: price.gross.currencyCode,
                    },
                },
                originalPrice: originalPrice && {
                    gross: {
                        amount: Number.parseFloat(originalPrice.gross.amount),
                        currencyCode: originalPrice.gross.currencyCode,
                    },
                },
                title,
                discountBadgeId,
                discountBadgeName,
                locationCount,
                ...(participants?.min && participants.max
                    ? {
                          participants: {
                              min: participants.min || 0,
                              max: participants.max || 0,
                          },
                      }
                    : {}),
                productType: productType as unknown as TrackingProductType,
                locationIsSelected,
                closestLocationDistance,
            },
            quantity,
            variant: (productType === ProductType.ExperienceProduct
                ? 'experience_voucher'
                : productType) as TrackingVariant,
            itemListName: GA4ItemListName.Category,
        });

        const insightsClient = (await insightsClientPromise.current)?.default;
        if (insightsClient && queryId) {
            sendAlgoliaObjectIdsConvertedAfterSearchEvent({
                insightsClient,
                eventName: {
                    page: AlgoliaAnalyticsEventPage.ProductListingPage,
                    action: AlgoliaAnalyticsEventAction.ProductAddedToCart,
                    source: AlgoliaAnalyticsEventSource.ProductList,
                },
                indexName,
                queryId,
                objectIds: [productId],
            });
        }

        try {
            await promise;
            handleCartRedirection();
        } catch (error) {
            target.disabled = false;

            if (axios.isAxiosError(error) && error.response?.status === 406) {
                setAddToCartErrorType(addToCartError.MIXED_SHOPPING_ERROR);
                return;
            }

            setAddToCartErrorType(addToCartError.GENERAL_CART_ERROR);
        }
    };

    return (
        <div
            className={classNames('pt-2-5x pt-sm-0', {
                'pe-none opacity-25': status === SearchStatus.Pending,
            })}
        >
            <div className="grid">
                {productListItems.map((listItem, index) => {
                    if (index >= currentPageSize) return null;

                    if (
                        bundleBoxEnabled &&
                        listItem.type === ProductListItemType.BundleBoxPlpSlider &&
                        listItem.headline &&
                        listItem.content &&
                        isListView
                    ) {
                        const { content, headline, key } = listItem;

                        return (
                            <div
                                key={key}
                                className={classNames(
                                    styles.productItem,
                                    'position-relative g-col-12 g-col-xs-12 g-col-md-12 mb-3x mt-md-3x',
                                )}
                            >
                                <div className="mb-1x">
                                    <Headline title={headline} />
                                </div>
                                <PageTiles
                                    identifier={`${key}-${index}`}
                                    tiles={content}
                                    pageType={pageType}
                                    verticalPosition={index + 1}
                                    hasNavigationCreative={false}
                                    trackingPrefix={TrackingCreativePrefix.PageTile}
                                    productListItemType={ProductListItemType.BundleBoxPlpSlider}
                                />
                            </div>
                        );
                    }

                    if (
                        listItem.type === ProductListItemType.CuratedPlpSlider &&
                        listItem.headline &&
                        listItem.content &&
                        isListView
                    ) {
                        const { content, headline, key } = listItem;

                        return (
                            <div
                                key={key}
                                className={classNames(
                                    styles.productItem,
                                    'position-relative g-col-12 g-col-xs-12 g-col-md-12 mb-3x mt-md-3x',
                                )}
                            >
                                <div className="mb-1x">
                                    <Headline title={headline} />
                                </div>
                                <PageTiles
                                    identifier={`${key}-${index}`}
                                    tiles={content}
                                    pageType={pageType}
                                    verticalPosition={index + 1}
                                    hasNavigationCreative={false}
                                    trackingPrefix={TrackingCreativePrefix.PageTile}
                                />
                            </div>
                        );
                    }

                    if (listItem.type === ProductListItemType.Product && !!listItem.product) {
                        const { key, product } = listItem;

                        productPosition += 1;

                        return (
                            <div
                                key={key}
                                className={classNames(styles.productItem, 'position-relative', {
                                    'mt-0 mt-sm-1-5x mb-3x': !isListView,
                                    'g-col-12 g-col-xs-6 g-col-md-12 mb-3x mb-md-0': isListView,
                                    'g-col-12 g-col-xs-6 g-col-md-3 mb-md-0':
                                        isListView && isFullWidth,
                                    'g-col-12 g-col-xs-6 g-col-md-4': !isListView && !isFullWidth,
                                })}
                            >
                                <ProductTileWrapper
                                    product={product}
                                    position={
                                        pagination && pagination.currentPage > 1
                                            ? pagination.size * (pagination.currentPage - 1) +
                                              productPosition
                                            : productPosition
                                    }
                                    lazyLoad={lazyLoad && index !== 0}
                                    onAddToCartButtonClick={handleAddToCart}
                                    queryId={queryId}
                                    indexName={indexName}
                                    isListView={isListView}
                                    hasLocationFilter={!!filter?.location?.name}
                                    closestLocationDistance={
                                        filter?.location?.name
                                            ? filter.location.distance
                                            : undefined
                                    }
                                />
                            </div>
                        );
                    }

                    if (!showBanner) return null;

                    if (
                        isListView &&
                        [
                            ProductListItemType.BannerSelectionBlock,
                            ProductListItemType.ProductListBanner,
                        ].includes(listItem.type)
                    ) {
                        listCreativeIndex += 1;
                    }

                    const bannerCreatives = getBannerCreatives(listItem.type);

                    return bannerCreatives.map(creative => (
                        <ProductBanner
                            key={`${listItem.key}-${creative.placeholderId}`}
                            creative={creative}
                            index={index}
                            verticalPosition={verticalPosition}
                            listCreativeIndex={listCreativeIndex}
                            className={classNames(styles.productItem, 'position-relative', {
                                'g-col-12 g-col-xs-6 g-col-md-4 mt-0 mt-sm-1-5x mb-3x':
                                    listItem.type === ProductListItemType.ProductTileBanner,
                                'g-col-12 g-col-xs-6 g-col-md-12 mt-md-2x mb-md-0-5x':
                                    listItem.type === ProductListItemType.ProductListBanner,
                                'mt-md-2x mb-md-0-5x':
                                    listItem.type === ProductListItemType.BannerSelectionBlock &&
                                    isListView,
                                'g-col-12 mt-0 mt-sm-1-5x mb-3x':
                                    listItem.type === ProductListItemType.BannerSelectionBlock,
                            })}
                            pageType={pageType}
                            isListView={isListView}
                        />
                    ));
                })}
            </div>
            {showPagination && (
                <ProductListPagination
                    path={path}
                    totalPages={nbPages}
                    initialPageFilter={initialPageFilter}
                    offset={offset}
                    search={{ searchTerm, filter, sorting, pagination }}
                />
            )}
        </div>
    );
};

export { ProductListItems };
