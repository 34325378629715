import { TenantEnum } from '@jsmdg/tenancy';
import { CuratedPlpSliderLocation } from '../../shared/enums/curatedPlpSliderLocation';
import { ProductListItemType } from '../enums/productListItemType';
import { type ProductListItem } from '../types/productListItem';

export const useBundleBoxPlpSlider = (
    tenant?: TenantEnum,
    pageId?: string,
): ProductListItem | undefined => {
    if (!tenant || !pageId || tenant !== TenantEnum.MD_DE) return undefined;

    if (pageId === CuratedPlpSliderLocation.Kurzurlaub)
        return {
            key: 'bundle-box-plp-slider',
            type: ProductListItemType.BundleBoxPlpSlider,
            headline: 'Passt immer: Unsere Geschenkboxen',
            content: [
                {
                    pageId: '93hc1',
                    title: 'Geschenkbox 3 Tage Du & Ich',
                    image: {
                        alt: 'Geschenkbox 3 Tage Du & Ich',
                        url: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/3 Tage Du & Ich.png',
                        width: 480,
                        height: 480,
                        originalSrc:
                            'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/3 Tage Du & Ich.png',
                        src: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/3 Tage Du & Ich.png',
                        srcset: '',
                        sizes: '',
                    },
                    url: '/geschenkboxen/geschenkbox-3-tage-du-und-ich/p/93hc1',
                },
                {
                    pageId: '2entq',
                    title: 'Geschenkbox Städtetrips',
                    image: {
                        alt: 'Geschenkbox Städtetrips',
                        url: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Städtetrips.png',
                        width: 480,
                        height: 480,
                        originalSrc:
                            'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Städtetrips.png',
                        src: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Städtetrips.png',
                        srcset: '',
                        sizes: '',
                    },
                    url: '/geschenkboxen/geschenkbox-staedtetrips/p/2entq',
                },
                {
                    pageId: '8yuf5',
                    title: 'Geschenkbox Wellnesshotels',
                    image: {
                        alt: 'Geschenkbox Wellnesshotels',
                        url: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Wellnesshotels.png',
                        width: 480,
                        height: 480,
                        originalSrc:
                            'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Wellnesshotels.png',
                        src: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Wellnesshotels.png',
                        srcset: '',
                        sizes: '',
                    },
                    url: '/geschenkboxen/geschenkbox-wellnesshotels/p/8yuf5',
                },
                {
                    pageId: 'fwsve',
                    title: 'Geschenkbox Kurz mal raus',
                    image: {
                        alt: 'Geschenkbox Kurz mal raus',
                        url: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Kurz mal raus.png',
                        width: 480,
                        height: 480,
                        originalSrc:
                            'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Kurz mal raus.png',
                        src: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Kurz mal raus.png',
                        srcset: '',
                        sizes: '',
                    },
                    url: '/geschenkboxen/geschenkbox-kurz-mal-raus/p/fwsve',
                },
                {
                    pageId: '728zc',
                    title: 'Geschenkbox Städtetrip Europa',
                    image: {
                        alt: 'Geschenkbox Städtetrip Europa',
                        url: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Städtetrip Europa.png',
                        width: 480,
                        height: 480,
                        originalSrc:
                            'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Städtetrip Europa.png',
                        src: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Städtetrip Europa.png',
                        srcset: '',
                        sizes: '',
                    },
                    url: '/geschenkboxen/geschenkbox-staedtetrip-europa/p/728zc',
                },
                {
                    pageId: '1xrua',
                    title: 'Geschenkbox Kurzurlaub für Zwei',
                    image: {
                        alt: 'Geschenkbox Kurzurlaub für Zwei',
                        url: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Kurzurlaub für Zwei.png',
                        width: 480,
                        height: 480,
                        originalSrc:
                            'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Kurzurlaub für Zwei.png',
                        src: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Kurzurlaub für Zwei.png',
                        srcset: '',
                        sizes: '',
                    },
                    url: '/geschenkboxen/geschenkbox-kurzurlaub-fuer-zwei/p/1xrua',
                },
                {
                    pageId: 'd5y8x',
                    title: 'Geschenkbox Wellnessreisen',
                    image: {
                        alt: 'Geschenkbox Wellnessreisen',
                        url: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Wellnessreisen.png',
                        width: 480,
                        height: 480,
                        originalSrc:
                            'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Wellnessreisen.png',
                        src: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Wellnessreisen.png',
                        srcset: '',
                        sizes: '',
                    },
                    url: '/geschenkboxen/geschenkbox-wellnessreisen/p/d5y8x',
                },
                {
                    pageId: 'xzkd8',
                    title: 'Geschenkbox Einzigartige Nächte',
                    image: {
                        alt: 'Geschenkbox Einzigartige Nächte',
                        url: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Einzigartige Nächte.png',
                        width: 480,
                        height: 480,
                        originalSrc:
                            'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Einzigartige Nächte.png',
                        src: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Einzigartige Nächte.png',
                        srcset: '',
                        sizes: '',
                    },
                    url: '/geschenkboxen/geschenkbox-einzigartige-naechte/p/xzkd8',
                },
                {
                    pageId: 'w29mx',
                    title: 'Geschenkbox Romantikurlaub',
                    image: {
                        alt: 'Geschenkbox Romantikurlaub',
                        url: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Romantikurlaub.png',
                        width: 480,
                        height: 480,
                        originalSrc:
                            'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Romantikurlaub.png',
                        src: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Romantikurlaub.png',
                        srcset: '',
                        sizes: '',
                    },
                    url: '/geschenkboxen/geschenkbox-romantikurlaub/p/w29mx',
                },
                {
                    pageId: 'uk4yh',
                    title: 'Geschenkbox Endlich Urlaub',
                    image: {
                        alt: 'Geschenkbox Endlich Urlaub',
                        url: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Endlich Urlaub.png',
                        width: 480,
                        height: 480,
                        originalSrc:
                            'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Endlich Urlaub.png',
                        src: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Endlich Urlaub.png',
                        srcset: '',
                        sizes: '',
                    },
                    url: '/geschenkboxen/geschenkbox-endlich-urlaub/p/uk4yh',
                },
                {
                    pageId: 'yeoh9',
                    title: 'Geschenkbox Unterwegs in Europa',
                    image: {
                        alt: 'Geschenkbox Unterwegs in Europa',
                        url: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Unterwegs in Europa.png',
                        width: 480,
                        height: 480,
                        originalSrc:
                            'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Unterwegs in Europa.png',
                        src: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Unterwegs in Europa.png',
                        srcset: '',
                        sizes: '',
                    },
                    url: '/geschenkboxen/geschenkbox-unterwegs-in-europa/p/yeoh9',
                },
                {
                    pageId: '3gvue',
                    title: 'Geschenkbox Nachhaltige Nächte',
                    image: {
                        alt: 'Geschenkbox Nachhaltige Nächte',
                        url: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Nachhaltige Nächte.png',
                        width: 480,
                        height: 480,
                        originalSrc:
                            'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Nachhaltige Nächte.png',
                        src: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Nachhaltige Nächte.png',
                        srcset: '',
                        sizes: '',
                    },
                    url: '/geschenkboxen/geschenkbox-nachhaltige-naechte/p/3gvue',
                },
            ],
        };

    if (pageId === CuratedPlpSliderLocation.ForCouples)
        return {
            key: 'bundle-box-plp-slider',
            type: ProductListItemType.BundleBoxPlpSlider,
            headline: 'Passt immer: Unsere Geschenkboxen',
            content: [
                {
                    pageId: '93hc1',
                    title: 'Geschenkbox 3 Tage Du & Ich',
                    image: {
                        alt: 'Geschenkbox 3 Tage Du & Ich',
                        url: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/3 Tage Du & Ich.png',
                        width: 480,
                        height: 480,
                        originalSrc:
                            'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/3 Tage Du & Ich.png',
                        src: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/3 Tage Du & Ich.png',
                        srcset: '',
                        sizes: '',
                    },
                    url: '/geschenkbox-3-tage-du-und-ich/p/93hc1',
                },
                {
                    pageId: '2entq',
                    title: 'Geschenkbox Städtetrips',
                    image: {
                        alt: 'Geschenkbox Städtetrips',
                        url: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Städtetrips.png',
                        width: 480,
                        height: 480,
                        originalSrc:
                            'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Städtetrips.png',
                        src: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Städtetrips.png',
                        srcset: '',
                        sizes: '',
                    },
                    url: '/geschenkbox-staedtetrips/p/2entq',
                },
                {
                    pageId: 'gs1hm',
                    title: 'Geschenkbox Zum Geburtstag',
                    image: {
                        alt: 'Geschenkbox Zum Geburtstag',
                        url: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Geburtstag.png',
                        width: 480,
                        height: 480,
                        originalSrc:
                            'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Geburtstag.png',
                        src: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Geburtstag.png',
                        srcset: '',
                        sizes: '',
                    },
                    url: '/geschenkboxen/geschenkbox-geburtstag/p/gs1hm',
                },
                {
                    pageId: 'mn17k',
                    title: 'Geschenkbox Zeit zu zweit',
                    image: {
                        alt: 'Geschenkbox Zeit zu zweit',
                        url: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Zeit zu zweit.png',
                        width: 480,
                        height: 480,
                        originalSrc:
                            'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Zeit zu zweit.png',
                        src: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Zeit zu zweit.png',
                        srcset: '',
                        sizes: '',
                    },
                    url: '/geschenkbox-zeit-zu-zweit/p/mn17k',
                },
                {
                    pageId: '8yuf5',
                    title: 'Geschenkbox Wellnesshotels',
                    image: {
                        alt: 'Geschenkbox Wellnesshotels',
                        url: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Wellnesshotels.png',
                        width: 480,
                        height: 480,
                        originalSrc:
                            'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Wellnesshotels.png',
                        src: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Wellnesshotels.png',
                        srcset: '',
                        sizes: '',
                    },
                    url: '/geschenkboxen/geschenkbox-wellnesshotels/p/8yuf5',
                },
                {
                    pageId: 'fwsve',
                    title: 'Geschenkbox Kurz mal raus',
                    image: {
                        alt: 'Geschenkbox Kurz mal raus',
                        url: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Kurz mal raus.png',
                        width: 480,
                        height: 480,
                        originalSrc:
                            'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Kurz mal raus.png',
                        src: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Kurz mal raus.png',
                        srcset: '',
                        sizes: '',
                    },
                    url: '/geschenkboxen/geschenkbox-kurz-mal-raus/p/fwsve',
                },
                {
                    pageId: '728zc',
                    title: 'Geschenkbox Städtetrip Europa',
                    image: {
                        alt: 'Geschenkbox Städtetrip Europa',
                        url: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Städtetrip Europa.png',
                        width: 480,
                        height: 480,
                        originalSrc:
                            'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Städtetrip Europa.png',
                        src: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Städtetrip Europa.png',
                        srcset: '',
                        sizes: '',
                    },
                    url: '/geschenkboxen/geschenkbox-staedtetrip-europa/p/728zc',
                },
                {
                    pageId: '1xrua',
                    title: 'Geschenkbox Kurzurlaub für Zwei',
                    image: {
                        alt: 'Geschenkbox Kurzurlaub für Zwei',
                        url: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Kurzurlaub für Zwei.png',
                        width: 480,
                        height: 480,
                        originalSrc:
                            'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Kurzurlaub für Zwei.png',
                        src: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Kurzurlaub für Zwei.png',
                        srcset: '',
                        sizes: '',
                    },
                    url: '/geschenkboxen/geschenkbox-kurzurlaub-fuer-zwei/p/1xrua',
                },
                {
                    pageId: 'd5y8x',
                    title: 'Geschenkbox Wellnessreisen',
                    image: {
                        alt: 'Geschenkbox Wellnessreisen',
                        url: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Wellnessreisen.png',
                        width: 480,
                        height: 480,
                        originalSrc:
                            'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Wellnessreisen.png',
                        src: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Wellnessreisen.png',
                        srcset: '',
                        sizes: '',
                    },
                    url: '/geschenkboxen/geschenkbox-wellnessreisen/p/d5y8x',
                },
                {
                    pageId: '9ae47',
                    title: 'Geschenkbox Zur Hochzeit',
                    image: {
                        alt: 'Geschenkbox Zur Hochzeit',
                        url: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Hochzeit.png',
                        width: 480,
                        height: 480,
                        originalSrc:
                            'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Hochzeit.png',
                        src: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Hochzeit.png',
                        srcset: '',
                        sizes: '',
                    },
                    url: '/geschenkboxen/geschenkbox-hochzeit/p/9ae47',
                },
                {
                    pageId: '5m61o',
                    title: 'Geschenkbox Zum Geburtstag für 2',
                    image: {
                        alt: 'Geschenkbox Zum Geburtstag für 2',
                        url: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Geburtstag für zwei.png',
                        width: 480,
                        height: 480,
                        originalSrc:
                            'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Geburtstag für zwei.png',
                        src: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Geburtstag für zwei.png',
                        srcset: '',
                        sizes: '',
                    },
                    url: '/geschenkboxen/geschenkbox-geburtstag-fuer-zwei/p/5m61o',
                },
                {
                    pageId: 'zra5v',
                    title: 'Geschenkbox Für Paare',
                    image: {
                        alt: 'Geschenkbox Für Paare',
                        url: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Paare.png',
                        width: 480,
                        height: 480,
                        originalSrc:
                            'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Paare.png',
                        src: 'https://main.static.jsmd-group.com/assets/plp_gift-boxes_test/Paare.png',
                        srcset: '',
                        sizes: '',
                    },
                    url: '/geschenkboxen/geschenkbox-paare/p/zra5v',
                },
            ],
        };

    return undefined;
};
