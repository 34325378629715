import { TenantEnum } from '@jsmdg/tenancy';
import { HeroStageType } from '@jsmdg/yoshi/dist/enums';
import { type TenantConfig } from '../shared/types/tenantConfig';
import { CategoryId } from './enums/categoryId';

enum BrandName {
    JochenSweizer = 'Jochen Schweizer',
    MyDays = 'mydays',
}

const corporateFormDescription =
    'Gerne beantworten wir alle Ihre Fragen zu den Erlebnissen für Firmen von ';

const categoryMap = [
    {
        categoryId: CategoryId.Fly,
        label: 'Fliegen & Fallen',
        url: '/fliegen-fallen/l/p3if5',
    },
    {
        categoryId: CategoryId.Trip,
        label: 'Kurzurlaub',
        url: '/kurzurlaub/l/d48wi',
    },
    {
        categoryId: CategoryId.Car,
        label: 'Motorpower',
        url: '/motorpower/l/p6jmw',
    },
    {
        categoryId: CategoryId.Dinner,
        label: 'Essen & Trinken',
        url: '/essen-trinken/l/9net3',
    },
    {
        categoryId: CategoryId.IndoorActivities,
        label: 'Indoor Aktivitäten',
        url: '/indoor-aktivitaeten/l/9v3b5',
    },
    {
        categoryId: CategoryId.OutdoorActivities,
        label: 'Outdoor Aktivitäten',
        url: '/outdoor-aktivitaeten/l/6gupe',
    },
    {
        categoryId: CategoryId.Health,
        label: 'Wellness',
        url: '/wellness-gesundheit/l/we5gm',
    },
    {
        categoryId: CategoryId.WaterSport,
        label: 'Wassersport',
        url: '/wasser-wind/l/ptauo',
    },
    {
        categoryId: CategoryId.Art,
        label: 'Kunst, Kultur & Lifestyle',
        url: '/kunst-kultur-lifestyle/l/26n8g',
    },
    {
        categoryId: CategoryId.Animals,
        label: 'Erlebnisse mit Tieren',
        url: '/tierliebhaber/l/749tv',
    },
    {
        categoryId: CategoryId.Adventure,
        label: 'Erlebnistage',
        url: ' /erlebnistage/l/7ncx1',
    },
    {
        categoryId: CategoryId.RegionActivities,
        label: 'Aktivitäten nach Region',
        url: '/aktivitaeten-nach-region/l/sv786',
    },
];

const jsdeConfig: TenantConfig = {
    allExperiencesLabel: 'Alle Erlebnisse',
    allExperiencesPath: '/l/79ifa',
    onlineExperienceOptions: ['Landesweit', 'Weltweit'],
    websiteMarkup: {
        name: 'Jochen Schweizer',
        alternativeName: 'Jochen Schweizer Erlebnisse',
    },
    heroStageType: HeroStageType.DiagonalHero,
    noSearchResults: {
        title: {
            messageId: 'titleJS',
        },
        firstTip: {
            messageId: 'firstTipJS',
        },
        secondTip: {
            messageId: 'secondTipJS',
        },
        thirdTip: {
            messageId: 'thirdTip',
        },
        customerService: {
            messageId: 'customerService',
        },
        redemptionLink: {
            messageId: 'redemptionLink',
        },
    },
    corporateFormDescription: `${corporateFormDescription}Jochen Schweizer.`,
    currency: { code: 'EUR', symbol: '€' },
    brandName: BrandName.JochenSweizer,
    contactInfo: { phone: { label: '', number: '' } },
    urls: {
        b2bContact: '',
        redeemVoucher: '',
        privacyPolicy: '',
        contact: '',
        redemption: '',
    },
    tenantContacts: {
        at: {
            label: '',
            number: '',
        },
        de: {
            label: '',
            number: '',
        },
        ch: {
            label: '',
            number: '',
        },
    },
};

const mdDEConfig: TenantConfig = {
    ...jsdeConfig,
    allExperiencesPath: '/l/o7trc',
    websiteMarkup: {
        name: 'mydays',
        alternativeName: 'mydays Erlebnisse',
    },
    heroStageType: HeroStageType.BoxHero,
    noSearchResults: {
        ...jsdeConfig.noSearchResults,
        title: {
            messageId: 'titleMD',
        },
        firstTip: {
            messageId: 'firstTipMD',
        },
        secondTip: {
            messageId: 'secondTipMD',
        },
    },
    corporateFormDescription: `${corporateFormDescription}mydays.`,
    brandName: BrandName.MyDays,
};

/**
 * @TODO update mydays config
 */
const tenantConfigMapping: Record<string, TenantConfig> = {
    [TenantEnum.JS_DE]: { ...jsdeConfig, categoryMap },
    [TenantEnum.JS_AT]: { ...jsdeConfig, categoryMap },
    [TenantEnum.MD_DE]: mdDEConfig,
    [TenantEnum.MD_AT]: mdDEConfig,
    [TenantEnum.MD_CH]: {
        ...mdDEConfig,
        currency: { code: 'CHF', symbol: 'CHF' },
    },
};

const getTenantConfig = (tenant: TenantEnum): TenantConfig => {
    return tenantConfigMapping[tenant] || tenantConfigMapping[TenantEnum.JS_DE];
};

export { getTenantConfig };
